/**
 * 获取当前语言基础路径、语言
 *
 * return {base, lang, site_region}
 * base: 语言基础路径 '/'、/zh-hans/、/ar/
 * */
import userManager from "@/utils/sso";

export function LangAndBasePathObj(site, baseURl) {
	let lang, host, site_region, base, pathname, firstpathname, browserLang;
	// console.log(import.meta.env);
	if (site === "hb") {
		lang = "zh-hans";
		site_region = 1;
		setBaseLang(lang, "hb");
		base = "/";
	} else {
		site_region = 2;
		browserLang = navigator.language;
		pathname = window.location.pathname;
		firstpathname = pathname.split("/")[1];
		//删除第一个路径
		const otherPathname = pathname.split("/").splice(2).join("/");
		switch (firstpathname) {
			case "en":
				lang = "en";
				base = "/";
				setBaseLang(lang);
				window.history.replaceState({}, null, `${baseURl}/${otherPathname}`);
				break;
			case "ar":
				lang = "ar";
				base = "/ar";
				setBaseLang(lang);
				if (!otherPathname) {
					window.history.replaceState({}, null, `${baseURl}${base}`);
				}
				break;
			case "zh-Hans":
			case "zh-hans":
				lang = "zh-hans";
				base = "/zh-hans";
				setBaseLang(lang);
				if (!otherPathname) {
					window.history.replaceState({}, null, `${baseURl}${base}`);
				}
				break;
			case "":
				lang = localStorage.getItem("lang");
				if (lang) {
					switch (lang) {
						case "en":
							base = "/";
							lang = "en";
							setBaseLang(lang);
							break;
						case "ar":
							base = "/ar";
							lang = "ar";
							setBaseLang(lang);
							break;
						case "zh-hans":
							base = "/zh-hans";
							lang = "zh-hans";
							setBaseLang(lang);
							break;
						default:
							break;
					}
				} else {
					// 浏览器语言
					switch (browserLang) {
						case "zh-hans":
						case "zh":
						case "zh-CN":
							base = "/zh-hans";
							lang = "zh-hans";
							setBaseLang(lang);
							break;
						case "en":
							base = "/";
							lang = "en";
							setBaseLang(lang);
							break;
						case "ar":
							base = "/ar";
							lang = "ar";
							setBaseLang(lang);
							break;
						default:
							base = "/";
							lang = "en";
							setBaseLang(lang);
							break;
					}
				}
				break;
			default:
				if (localStorage.getItem("lang")) {
					switch (localStorage.getItem("lang")) {
						case "zh-hans":
							base = "/zh-hans";
							lang = "zh-hans";
							break;
						case "en":
							base = "/";
							lang = "en";
							break;
						case "ar":
							base = "/ar";
							lang = "ar";
							break;
					}
				} else {
					if (browserLang) {
						switch (browserLang) {
							case "zh-hans":
							case "zh":
							case "zh-CN":
								base = "/zh-hans";
								lang = "zh-hans";
								setBaseLang(lang);
								break;
							case "en":
								base = "/";
								lang = "en";
								setBaseLang(lang);
								break;
							case "ar":
								base = "/";
								lang = "ar";
								setBaseLang(lang);
								break;
							default:
								base = "/";
								lang = "en";
								setBaseLang(lang);
								break;
						}
					} else {
						base = "/";
						lang = "en";
					}
				}
		}
	}

	return {
		lang,
		site_region,
		base,
	};
}

function setBaseLang(lang, site) {
	const htmlElement = document.getElementsByTagName("html")[0];
	if (site === "hb") {
		htmlElement.setAttribute("lang", "zh");
	} else {
		htmlElement.setAttribute("lang", lang);
	}
	localStorage.setItem("lang", lang);
}

import { ref } from "vue";

export function useIsMobile() {
	const isMobile = ref(
		/Android|webOS|iPhone|iPod|BlackBerry|iPad|SymbianOS/i.test(
			navigator.userAgent,
		) && window.innerWidth <= 768,
	);
	return isMobile;
}

//将i18n的值对应组合起来
export function connectingWords(arr) {
	let str = "";
	arr.forEach((element, index) => {
		if (index === arr.length - 1) {
			str += element;
		} else {
			str += `${element}/`;
		}
	});
	return str;
}

//返回auth默认的值
export function getAuthDefaultVal(arr) {
	// 设置auth的state
	return {
		response_type: "code",
		client_id: "dnachron",
		scope: "openid profile email",
	};
}

//获取当前语言
export function getCurrentLang() {
	let lang = localStorage.getItem("lang");
	if (lang === "zh-hans") {
		lang = "zh-hans";
	}
	return lang;
}

//导入i18n
import i18n from "@/i18n/index";
//消息提示词
export function getRequiredError(message) {
	// console.log(i18n.global.t("common.login"), "locale");

	if (getCurrentLang() === "zh-hans") {
		return `${i18n.global.t("common.PleaseEnterThe")}${message}`;
	} else {
		return `${i18n.global.t("common.PleaseEnterThe")} ${message}`;
	}
}

/**
 * 根据当前语言 document.title 动态设置页面标题
 * curPageObj: 路由到的页面对象
 * extraStr: 额外的标题
 * return {}
 * */
export function documentTitle(curPageObj, extraStr = "") {
	extraStr ? (extraStr = ` - ${extraStr}`) : (extraStr = "");
	console.log(extraStr, "extraStr");
	let _LangAndBasePathObj = LangAndBasePathObj(
		import.meta.env.VITE_APP_SITE_ENV,
		window.location.origin,
	);
	if (curPageObj.meta.title) {
		console.log(_LangAndBasePathObj, "标题语言是");
		switch (_LangAndBasePathObj.lang) {
			case "zh-hans":
				document.title = curPageObj.meta.title + extraStr;
				break;
			case "en":
				document.title = curPageObj.meta.title_en + extraStr;
				break;
			case "ar":
				document.title = curPageObj.meta.title_ar + extraStr;
				break;
			default:
				document.title = curPageObj.meta.title_en + extraStr;
				break;
		}
	}
}

//检验是否有acceess_token
export function checkAndGetToken() {
	let obj = JSON.parse(
		localStorage.getItem(
			`oidc.user:${import.meta.env.VITE_APP_BASE_URL}/auth:dnachron`,
		),
	);
	if (!obj) {
		return false;
	} else {
		return obj.access_token;
	}
}

import { useUserInfoStore } from "@/store/modules/useUserInfoStore";
//清空数据
export function clearData() {
	// 清除user信息
	localStorage.removeItem("userInfo");

	clearOidcData();

	userManager.removeUser();
}

// 判断user中的access_token是否还剩一分钟过期
export async function checkTokenExpired() {
	try {
		const user = await userManager.getUser(); // 使用await获取user

		// 使用可选链操作符，避免访问空对象
		if (user?.expires_at) {
			const now = Math.floor(Date.now() / 1000);
			if (user.expires_at - now > 60) {
				// 没有过期
				return false;
			} else {
				// 过期
				return true;
			}
		} else {
			// 如果没有exp属性或用户未登录
			return true; // 假设如果没有exp或未登录，则认为token已过期
		}
	} catch (error) {
		console.log(error, "checkTokenExpired");
		throw error;
	}
}

import UAParser from "ua-parser-js";
//判断当前是手机还是PC模式
export function getCurrentMode() {
	let parser = new UAParser();
	let result = parser.getResult();

	//获取设备类型和分辨率
	let device = result.device.type; // console, mobile, tablet, smarttv, wearable, embedded
	let screenWidth = window.innerWidth;
	console.log(screenWidth, "screenWidth");

	//根据设备类型和分辨率判断当前模式
	if (
		screenWidth <= 768 ||
		device === "mobile" ||
		(device === "tablet" && screenWidth < 1024)
	) {
		return "mobile";
	} else {
		return "pc";
	}
}

export function debounce(fn, delay) {
	let timer = null; // 定义一个定时器

	return function (...args) {
		// 如果定时器存在，则清除之前的定时器
		if (timer) clearTimeout(timer);

		// 重新启动一个定时器，设置延迟执行
		timer = setTimeout(() => {
			fn.apply(this, args); // 延迟结束后执行函数
		}, delay);
	};
}

export function throttle(func, delay) {
	let lastTime = 0;
	return function (...args) {
		const now = Date.now();
		if (now - lastTime >= delay) {
			lastTime = now;
			func.apply(this, args);
		}
	};
}

// userInfo白名单
export const userInfoWhiteList = ["id", "username", "profile"];
// 筛选存储在localStorage的userInfo信息
export function getUserInfo(userInfo) {
	let info = {};
	// 筛选出白名单中的字段
	userInfoWhiteList.forEach((key) => {
		info[key] = userInfo[key];
	});
	return info;
}

// 解决新版本中的的localStorage存储数据冲突问题
export function checkLocalStorageConflict() {
	// 删除旧版本中的数据(token,username,username_DNAchron,password_DNAchron,site_reminder_no_show,site_region)
	if (localStorage.getItem("username")) {
		localStorage.removeItem("username");
	}
	if (localStorage.getItem("token")) {
		localStorage.removeItem("token");
	}
	if (localStorage.getItem("username_DNAchron")) {
		localStorage.removeItem("username_DNAchron");
	}
	if (localStorage.getItem("password_DNAchron")) {
		localStorage.removeItem("password_DNAchron");
	}
	if (localStorage.getItem("site_reminder_no_show")) {
		localStorage.removeItem("site_reminder_no_show");
	}
	if (localStorage.getItem("site_region")) {
		localStorage.removeItem("site_region");
	}
	// 如果存储了zh,需要修改为zh-hans
	if (localStorage.getItem("lang") === "zh") {
		localStorage.setItem("lang", "zh-hans");
	}
}

// 刷新 token 的函数
export async function refreshToken() {
	if (!useUserInfoStore.tokenRefreshPromise) {
		// 如果没有正在进行的刷新操作，则创建新的刷新操作
		useUserInfoStore.tokenRefreshPromise = userManager
			.signinSilent()
			.then(() => {
				useUserInfoStore.tokenRefreshPromise = null; // 刷新完成，清除标志位
			})
			.catch((e) => {
				console.log(e.error, "signinSilent error");
				if (e.error === "invalid_grant") {
					clearData();
				}
				useUserInfoStore.tokenRefreshPromise = null; // 刷新失败，也要清除标志位
			});
	}
	// 等待当前的刷新操作完成
	await useUserInfoStore.tokenRefreshPromise;
}

export function clearOidcData() {
	// 获取所有 localStorage 键
	const keys = Object.keys(localStorage);

	console.log(keys, "keys是什么啊");

	// 遍历所有键并清除以 'oidc' 开头的项
	keys.forEach((key) => {
		if (key.startsWith("oidc")) {
			localStorage.removeItem(key);
		}
	});
}

// 清除pathname中的语言
export function clearLang(value) {
	let pathname = value;
	if (pathname === "/") {
		return pathname;
	} else {
		let langList = ["/zh-hans", "/ar"];
		langList.forEach((item) => {
			if (pathname.includes(item)) {
				pathname = pathname.replace(item, "");
			}
		});
		return pathname;
	}
}

// 判断当前时间是否小于多少秒
export function isBefore(timestamp, time) {
	let now = Math.floor(new Date() / 1000);
	if (now - timestamp < time) {
		return true;
	} else {
		return false;
	}
}

// 关于语义化的方法
/**
 * 除了姓与名之外的字段全部独立
 * 也就是说我要把除了名字和姓氏关联在一起，其它都做成单独的根据语言显示规则
 * 第一步，传入tag字段数组
 * @param {} tagAry
 * @param {*} sample_dict
 * @param {*} site
 */

const tag_dict = {
	family_name_info: "family_name",
	first_name_info: "first_name",
	population_info: "population",
	language_info: "language",
	title_info: "brief_title",
	branch_info_c_and_c_info: "branch_country_and_culture",
	c_and_c_info: "c_and_c",
	archaeological_site_name_info: "archaeological_site_name",
};
export function newShowTagAndValue(
	sample_dict,
	tagAry,
	is_disply_region_and_archaeological_site,
	site,
) {
	// 遍历tagAry字段
	for (let i = 0; i < tagAry.length; i++) {
		sample_dict[tag_dict[tagAry[i]]] = setValueByLanguage(
			tagAry[i],
			sample_dict,
			is_disply_region_and_archaeological_site,
			site,
		);
	}
	return sample_dict;
}

function setValueByLanguage(
	tagKey,
	sample_dict,
	is_disply_region_and_archaeological_site,
	site,
) {
	const currentLang = getLang();
	return getInfoByLanguage(
		sample_dict,
		tagKey,
		currentLang,
		is_disply_region_and_archaeological_site,
		site,
	);
}

function getLang() {
	let currentLang = localStorage.getItem("lang");
	if (currentLang == "zh-hans") {
		currentLang = "zh-Hans";
	}
	return currentLang;
}

function getInfoByLanguage(
	sample_dict,
	tagKey,
	currentLang,
	is_disply_region_and_archaeological_site,
	site,
) {
	// 名字与姓氏特殊处理
	if (tagKey == "family_name_info" || tagKey == "first_name_info") {
		// 判断当前语言的姓氏或者名字是否有一个有值，则都选择当前语言
		// console.log(getNameOrSurnameAboutResult(sample_dict, currentLang, tagKey),'1111111');
		return getNameOrSurnameAboutResult(sample_dict, currentLang, tagKey);
	} else {
		if (tagKey == "region_info" || tagKey == "archaeological_site_info") {
			if (!is_disply_region_and_archaeological_site) {
				// 优先展示籍贯，没有籍贯则展示出土地

				getSiteValueAboutResult(currentLang, site, sample_dict, tagKey);
			} else {
				set_region_info(sample_dict, currentLang, site, tagKey);
			}
		} else {
			return getAloneValueAboutResult(sample_dict, currentLang, tagKey);
		}
	}
}

// 独立处理的字段多语言
function getAloneValueAboutResult(sample_dict, currentLang, tagKey) {
	const result = isHasValue(sample_dict[tagKey], currentLang);
	if (result) {
		return getValueByLanguage(sample_dict, tagKey, currentLang);
	} else {
		const def_result = isHasValue(sample_dict[tagKey], "def");
		if (def_result) {
			return getValueByLanguage(sample_dict, tagKey, "def");
		} else {
			return getValueByLanguage(sample_dict, tagKey, "en");
		}
	}
}

function getNameOrSurnameAboutResult(sample_dict, currentLang, tagKey) {
	// console.log(sample_dict, 'sample_dict');

	const result = getResult(sample_dict, currentLang, tagKey);

	// 如果为真
	if (result) {
		console.log("进入当前语言");
		if (tagKey == "first_name_info") {
			return joinField(sample_dict, tagKey, currentLang);
		} else {
			return getValueByLanguage(sample_dict, tagKey, currentLang);
		}
	} else {
		const def_result = getResult(sample_dict, "def", tagKey);
		if (def_result) {
			console.log("进入def语言");
			if (tagKey == "first_name_info") {
				return joinField(sample_dict, tagKey, "def");
			} else {
				return getValueByLanguage(sample_dict, tagKey, "def");
			}
		} else {
			console.log("进入en语言");
			if (tagKey == "first_name_info") {
				return joinField(sample_dict, tagKey, "en");
			} else {
				return getValueByLanguage(sample_dict, tagKey, "en");
			}
		}
	}
}

function getResult(sample_dict, currentLang, tagKey) {
	const nameOrSurname = ["family_name_info", "first_name_info"];
	return nameOrSurname.some((item) => {
		if (!sample_dict || !sample_dict[item]) return false;
		// 检查 sample_dict[item] 是否为空
		return isHasValue(sample_dict[item], currentLang);
	});
}

function joinField(sample_dict, tagKey, currentLang) {
	if (sample_dict[tagKey] == null) {
		sample_dict[tagKey] = {};
	}
	let first_name, order, sep;
	if (sample_dict[tagKey][currentLang]) {
		first_name = sample_dict[tagKey][currentLang].first_name;
		order = sample_dict[tagKey][currentLang].order;
		sep = sample_dict[tagKey][currentLang].sep;
	} else {
		first_name = "";
		if (LangAndBasePathObj().site_region == 1) {
			order = 2;
			sep = "";
		} else {
			order = 1;
			sep = "\u00A0";
		}
	}
	sample_dict.sep = sep;
	sample_dict.order = order;
	return first_name;
}

// 设置值
function getValueByLanguage(sample_dict, key, lang) {
	if (!sample_dict) return "";
	// console.log(sample_dict, key, lang, '哈哈哈哈哈');
	return !isHasValue(sample_dict[key], lang) ? "" : sample_dict[key][lang];
}

// 验证是否为空对象
export function isEmptyObj(obj) {
	if (obj === null || obj === undefined) return true;
	if (Object.keys(obj).length === 0 && obj.constructor === Object) return true;
	else return false;
}

function isHasValue(obj, lang) {
	// console.log(obj, 'obj是什么  ');
	// 如果是空对象，返回 false
	if (isEmptyObj(obj)) return false;
	if (obj[lang]) {
		return true;
	} else {
		return false;
	}
}

export function getSiteValueAboutResult(
	currentLang,
	site,
	sample_dict,
	tagKey,
) {
	// 进来首先判断当前语言是否有籍贯，其次判断是否有def籍贯，en籍贯，如果都没有，否则显示出土地
	const result = getRegion(currentLang, site, sample_dict);

	if (result) {
		set_region_info(sample_dict, currentLang, site, tagKey);
	} else {
		set_region_info(sample_dict, currentLang, site, "archaeological_site_info");
	}
}

function getRegion(currentLang, site, list) {
	let result = false;
	const tagAry = ["region_info", "archaeological_site_info"];
	// 优先显示籍贯
	result = site.some((item) => {
		return (
			isHasValue(list[tagAry[0]][item], currentLang) ||
			isHasValue(list[tagAry[0]][item], "def") ||
			isHasValue(list[tagAry[0]][item], "en")
		);
	});

	return result;
}

function set_region_info(sample_dict, currentLang, site, tagKey) {
	let lang = currentLang;
	let prefix = "";
	if (tagKey == "archaeological_site_info") {
		prefix = "archaeological_";
	}
	for (let i = 0; i < site.length; i++) {
		if (lang === "def" && site[i] !== "county") {
			lang = localStorage.getItem("lang");
			if (lang === "zh-hans") {
				lang = "zh-Hans";
			}
		} else {
			lang = currentLang;
		}

		sample_dict[prefix + site[i]] =
			getValueByLanguage(sample_dict[tagKey], site[i], lang) ||
			getValueByLanguage(sample_dict[tagKey], site[i], "def") ||
			getValueByLanguage(sample_dict[tagKey], site[i], "en");

		if (!sample_dict[prefix + site[i]]) {
			delete sample_dict[prefix + site[i]];
		}
		lang = currentLang;
	}
}
