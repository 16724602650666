import microApp from "@micro-zoe/micro-app";
import { getActiveApps } from "@micro-zoe/micro-app";
import { createRouter, createWebHistory } from "vue-router";

import { useUAParserStore } from "@/store/modules/useUAParserStore";
// import tokenService from "@/utils/tokenService";

import { useUserInfoStore } from "@/store/modules/useUserInfoStore";

import {
	LangAndBasePathObj,
	checkAndGetToken,
	documentTitle,
} from "@/utils/index";
import userManager from "@/utils/sso";

const Home = () => import("@/views/layout/index.vue");
const routes = [
	{
		// path: "/:page*",
		path: "/",
		component: Home,
		name: "Home",
		meta: {
			keepAlive: false, //需要缓存
			// 页面标题title
			title: "基因志 - 探索未知历史，记录祖先源流",
			title_en: "DNAChron - Chronicle by DNA",
			title_ar: "DNAChron - Chronicle by DNA",
		},
		children: [
			{
				path: "/",
				name: "Index",
				component: () => import("@/views/home/index.vue"),
				meta: {
					keepAlive: true, //需要缓存
					// 页面标题title
					title: "基因志 - 探索未知历史，记录祖先源流",
					title_en: "DNAChron - Chronicle by DNA",
					title_ar: "DNAChron - Chronicle by DNA",
				},
			},
			// {
			// 	path: "/ytree/:char?/",
			// 	name: "NewYTree",
			// 	component: () => import("@/views/ytree/index.vue"),
			// 	meta: {
			// 		keepAlive: false,
			// 		title: "父系树 - 基因志",
			// 		title_en: "YTree - DNAChron",
			// 		title_ar: "YTree - DNAChron",
			// 	},
			// },
			{
				path: "/ytree/:char?/",
				name: "YTree",
				component: () => import("@/views/ytree/index.vue"),
				meta: {
					keepAlive: false,
					title: "父系树 - 基因志",
					title_en: "YTree - DNAChron",
					title_ar: "YTree - DNAChron",
				},
			},
			{
				path: "/isogg/:version?/:char?",
				name: "ISOGGtree",
				component: () => import("@/views/ytree/index.vue"),
				meta: {
					keepAlive: false,
					title: "ISOGG树 - 基因志",
					title_en: "ISOGG Tree - DNAChron",
					title_ar: "ISOGG Tree - DNAChron",
				},
			},
			{
				path: "/ytree/individual/:name?",
				name: "SampleDetail",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "检测人详情 - 基因志",
					title_en: "Individual Detail - DNAChron",
					title_ar: "Individual Detail - DNAChron",
				},
			},
			{
				path: "/ytree/branch/:ylink_node_id?",
				name: "BranchDetail",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "分支详情 - 基因志",
					title_en: "Branch Detail - DNAChron",
					title_ar: "Branch Detail - DNAChron",
				},
			},
			{
				path: "/isogg/branch/:ylink_node_id?",
				name: "BranchDetailISOGG",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "分支详情 - 基因志",
					title_en: "Branch Detail - DNAChron",
					title_ar: "Branch Detail - DNAChron",
				},
			},
			{
				path: "/whats-new",
				name: "Whatnews",
				component: () => import("@/views/whatnews/index.vue"),
				meta: {
					keepAlive: false,
					title: "用户动态 - 基因志",
					title_en: "Updates - DNAChron",
					title_ar: "Updates - DNAChron",
				},
			},
			{
				path: "/user-center/import-sample/:serial_number?",
				name: "ImportSample",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "导入数据 - 基因志",
					title_en: "Import Data - DNAChron",
					title_ar: "Import Data - DNAChron",
				},
			},
			{
				path: "/importsamplesuc",
				name: "ImportSampleSuc",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "导入完成 - 基因志",
					title_en: "Import Finished - DNAChron",
					title_ar: "Import Finished - DNAChron",
				},
			},
			{
				path: "/user-center/new-individual",
				name: "newSamplePeople",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "新建检测人 - 基因志",
					title_en: "New Individual - DNAChron",
					title_ar: "New Individual - DNAChron",
				},
			},
			{
				path: "/user-center/new-individual/success",
				name: "newSamplePeopleSuccess",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "新建检测人成功 - 基因志",
					title_en: "New Individual Success - DNAChron",
					title_ar: "New Individual Success - DNAChron",
				},
			},
			{
				path: "/user-center/setting/:serial_number?",
				name: "EditSamplePeople",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "编辑检测人 - 基因志",
					title_en: "Edit Individual - DNAChron",
					title_ar: "Edit Individual - DNAChron",
				},
			},
			{
				path: "/ancestry/:collated_name?/:letftabindex?",
				// path: "/ancestry/:collated_name?/:letftabindex?/:mutationName?/:mutationType?",
				name: "Ancestry",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: true,
					title: "祖源 - 基因志",
					title_en: "Ancestry - DNAChron",
					title_ar: "Ancestry - DNAChron",
				},
			},
			{
				path: "/ancestry-tools",
				name: "AncestralTool",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "祖源工具 - 基因志",
					title_en: "Ancestry Tool - DNAChron",
					title_ar: "Ancestry Tool - DNAChron",
				},
			},
			{
				path: "/user-center",
				name: "UserCenter",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "个人中心 - 基因志",
					title_en: "Personal Center - DNAChron",
					title_ar: "Personal Center - DNAChron",
				},
			},
			{
				path: "/user-center/my-data",
				name: "MyData",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "我的数据 - 基因志",
					title_en: "Sequence Data - DNAChron",
					title_ar: "Sequence Data - DNAChron",
				},
			},
			{
				path: "/user-center/profile",
				name: "Profile",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "账户管理 - 基因志",
					title_en: "Account - DNAChron",
					title_ar: "Account - DNAChron",
				},
			},
			{
				path: "/message",
				name: "Message",
				component: () => import("@/components/legacyLayer.vue"),
				meta: {
					keepAlive: false,
					title: "关于我们 - 消息或通知",
					title_en: "Message or Notification - DNAChron",
					title_ar: "Message or Notification - DNAChron",
				},
			},
			{
				path: "/:page*",
				name: "404",
				component: () => import("@/components/404.vue"),
				meta: {
					keepAlive: false,
					title: "404 - 基因志",
					title_en: "404 - DNAChron",
					title_ar: "404 - DNAChron",
				},
			},
		],
	},
	{
		path: "/authorize",
		name: "Authorize",
		component: () => import("@/components/authorize.vue"),
	},
];

const { base, lang } = LangAndBasePathObj(
	import.meta.env.VITE_APP_SITE_ENV,
	window.location.origin,
);
console.log(base, "base", import.meta.env.BASE_URL);
const router = createRouter({
	history: createWebHistory(base),
	routes: routes,
});

// 注册主应用路由
microApp.router.setBaseAppRouter(router);

// 路由守卫

router.beforeEach((to, from, next) => {
	if (to.path !== "/" && to.path.endsWith("/")) {
		const path = to.path.slice(0, -1);
		next({ path, query: to.query, hash: to.hash });
	} else {
		next();
	}
	if (window._hmt) {
		// 百度统计
		window._hmt.push(["_trackPageview", to.fullPath]);
	}

	console.log("子应用是否影响到主应用，监听路由变化");

	// //修改标题
	if (to.meta.title) {
		console.log(to, "修改标题");
		/* 设置页面title */
		documentTitle(to);
	}
	//动态设置meta标签信息
	let metaTags = document.getElementsByTagName("meta");
	for (let i = 0; i < metaTags.length; i++) {
		let propertyAttribute = metaTags[i].getAttribute("property");
		let nameAttribute = metaTags[i].getAttribute("name");
		let _to_meta_title = "";
		switch (lang) {
			case "zh-hans":
				_to_meta_title = to.meta.title;
				break;
			case "en":
				_to_meta_title = to.meta.title_en;
				break;
			case "ar":
				_to_meta_title = to.meta.title_ar;
				break;
			default:
				_to_meta_title = to.meta.title_en;
				break;
		}

		if (propertyAttribute === "og:title") {
			metaTags[i].setAttribute("content", _to_meta_title);
		}
		if (propertyAttribute === "twitter:title") {
			metaTags[i].setAttribute("content", _to_meta_title);
		}
		if (nameAttribute === "og:title") {
			metaTags[i].setAttribute("content", _to_meta_title);
		}
	}

	if (checLoginRouter(to.name) && !checkAndGetToken()) {
		// 判断是否有refresh_token 如果没有就显示登录遮罩层
		microApp.forceSetData("dnachron-vue2", { OpenOverlay: true });
	}

	if (!checkAndGetToken()) {
		useUserInfoStore().clearUserInfo();
	}

	//检查路由是否在白名单中,存在放行,不存在判断access_token是否存在
	if (!checkRouter(to.name)) {
		if (!checkAndGetToken()) {
			userManager.signinRedirect();
		} else {
			next();
		}
	} else {
		next();
	}
	document.body.scrollTo(0, 0);
	console.log(getActiveApps(), "获取活跃的子应用");
	console.log(to, "路由守卫2");
	if (checkVisitChildRouter(to.name)) {
		if (getActiveApps().includes("dnachron-vue2")) {
			microApp.router.push({
				name: "dnachron-vue2",
				path: (base !== "/" ? base : "") + to.fullPath,
				replace: true,
			});
		}
	}
});

router.afterEach((to, from) => {
	console.log(to, "to路由后置守卫");
	console.log(from, "from路由后置守卫");
});

// 前置路由守卫判断是否可以进入
export function checkRouter(pathName) {
	let routeWhiteList = [
		"Index",
		"Whatnews",
		"YTree",
		"ISOGGtree",
		"SampleDetail",
		"BranchDetail",
		"BranchDetailISOGG",
		"Authorize",
		"Ancestry",
		"ImportSample",
		"AncestralTool",
		"404",
	];
	//登录校验
	if (routeWhiteList.indexOf(pathName) > -1) {
		return true;
	} else {
		return false;
	}
}

// 显示登录后才可进入的路由的白名单
function checLoginRouter(name) {
	let ary = ["Ancestry", "ImportSample", "AncestralTool"];
	if (ary.indexOf(name) > -1) {
		return true;
	} else {
		return false;
	}
}

// 访问子路由的黑名单
function checkVisitChildRouter(name) {
	let ary = ["Index", "Whatnews", "YTree", "ISOGGtree"];
	if (ary.indexOf(name) > -1) {
		return false;
	} else {
		return true;
	}
}

export default router;
