const en = {
	common: {
		login: "Sign In",
		UserRegistration: "Create Account",
		ResetPassword: "Reset Password",
		Confirm: "Confirm",
		Cancel: "Cancel",
		Edit: "Edit",
		PhoneNumber: "Phone Number",
		Email: "Email",
		UserName: "Username",
		Password: "Password",
		VerificationCode: "Verification Code",
		ConfirmPassword: "Confirm Password",
		PleaseEnterThe: "Please enter the",
		EmailAlreadyExists:
			"There is already an account associated with the email ",
		PleaseLoginBindEmail:
			" Please log in and bind the third-party account in the personal center.",
		YouarevisitingDNAChronInternational:
			"You are visiting DNAChron International. We suggest you to visit DNAChron China according to your IP address.",
		Jumpto: "Jump to",
		Noprompt: "No prompt",
		yes: "Yes",
		no: "No",
		Save: "Save",
		Wearesorrythepageyourequestedcannotbefound:
			"We are sorry, the page you requested cannot be found.",
		BacktoHome: "Back to Home",
	},
	navbar: {
		YTree: "YTree",
		Ancestry: "Ancestry",
		ImportSample: "Import Data",
		Whatnews: "Updates",
		SignIn: "Sign in",
		SignUp: "Sign up",
		Notification: "Notification",
		PrivateMessage: "Private Message",
		AncestralTools: "Ancestry Tools",
		BrowseConfig: "Browse Config",
		PersonalCenter: "Personal Center",
		IndividualInformation: "Individual Information",
		SequenceData: "Sequence Data",
		Account: "Account",
		Logout: "Logout",
		TreeBrowseConfig: "Tree Browse Config",
		nodescountperpage: "nodes count per page",
		showdiscloseinformation: "Show Disclose Information",
		youcanviewyour:
			"You can view your private and authorized information after sign in.",
		showindividualname: "Show Individual First Name",
		showPopulation: "Show Population",
		showPopulationRule:
			"When this option is turned off, the population will still be displayed if the individual has no name information",
		language: "Language",
	},
	index: {
		Trace: "Trace the footsteps of human history",
		Tracethehuman: `Join users worldwide to trace the human journey with the world's first pure T2T YTree, offering double the accuracy in analyzing your genetic sequencing data to pinpoint your exact location on the human YTree.`,
		Tracethehuman1: "Join users worldwide to trace the human journey with ",
		Tracethehuman2: "the world's first pure T2T YTree",
		Tracethehuman3: ", offering ",
		Tracethehuman4: "double the accuracy ",
		Tracethehuman5:
			"in analyzing your genetic sequencing data to pinpoint your exact location on the human YTree.",
		Joinnow: "Explore YTree",
		Makeefficient: "Make efficient use of sequencing data",
		Ychromosomedata: `
			Y-chromosome data analysis can adaptively leverage up to 39M sequencing data, far beyond the traditional estimation interval (8M). This allows for a more precise age estimation with detailed probability ranges across different segments. The higher the coverage, the more accurate the results. For whole genome sequence samples or branches, we also incorporate INDELs and MNPs to further enhance precision.
			`,
		Ychromosomedata1:
			"Y-chromosome data analysis can adaptively leverage up to ",
		Ychromosomedata2: "39M ",
		Ychromosomedata3:
			"sequencing data, far beyond the traditional estimation interval (8M). This allows for a more precise age estimation with detailed probability ranges across different segments. The higher the coverage, the more accurate the results. For whole genome sequence samples or branches, we also incorporate INDELs and MNPs to further enhance precision.",
		Ychromosomedata4: "",
		Ychromosomedata5: "",
		Preciseanalysis: "Precise analysis",
		Basedon:
			"By efficiently leveraging sequencing data, we can uncover more mutations that aid in clarifying the structure of each Y-haplogroup, particularly for branches experiencing significant expansions, greatly enhancing the accuracy of your position on the YTree. Our advanced algorithms fully exploit all regions of the Y chromosome, especially the abundant palindromic and heterochromatic areas newly added in the T2T reference sequence, which are often beyond the reach of traditional methods, allowing us to achieve twice the mutation analysis accuracy. To support this, we offer a free re-alignment of your data to the T2T reference sequence.",
		Faston: "Quick Analysis",
		Afterthe:
			"After successfully downloading the data provided by the user and ensuring its integrity, we will complete the analysis within 2-5 working days and display the results on the Y-haplogroup tree, minimizing your waiting time and helping you get results quickly.",
		Convenientquery: "Convenient query of isogg tree",
		Fortheisogg:
			"For the isogg numbers used by papers, testing organizations, scientific institutions, etc., we provide isogg tree and number queries to meet your query needs.",
		PrivacySecurity: "Privacy & Security",
		Wewill:
			"We will store your registration information, tester information, analysis result information and genetic information data separately on the cloud, and protect them in all aspects through reasonable and feasible security measures such as technology, hardware and management process.",
		exploreAncestral: "Explore Ancestry",
		ISOGGTree: "ISOGG Tree",
		YTree: "YTree",
		ImportData: "Import Data",
	},
	Footer: {
		FAQ: "FAQ",
		Blog: "Blog",
		CustomerService: "Customer Service Email",
		Officialcommunication: "Official communication group",
		AboutUs: "About Us",
		TermofService: "Terms of Service",
		PrivacyPolicy: "Privacy Policy",
		ContactCustomerService: "Contact Service",
		ContactService: "Message Customer Service",
		InternationalSite: "International Site",
		ChinaSite: "China Site",
		Site: "Site",
	},
	ytree: {
		FuzzySearch: "FuzzySearch",
		branchDetail: "Branch Info",
		SnpOrISOgg: "SNP or ISOGG",
		HistoricalSearch: "History",
		Clear: "Clear",
		nofind: "No results found",
	},
	whatnews: {
		CreateDate: "Create Date",
		UpdateDate: "Update Date",
		YHaplogroup: "Y-HG",
		MTHaplogroup: "MT-HG",
		EstimatedAge: "TMRCA",
		Surname: "Surname",
		Name: "Name",
		FirstName: "First Name",
		BirthYear: "Birth Year",
		Period: "Culture",
		Origin: "Origin",
		Location: "Location",
		SiteName: "Site",
		Population: "Population",
	},
	statusTips: {
		0: "success",
		10010: "Password incorrect",
		10070: "User does not exist",
	},
};

export default en;
